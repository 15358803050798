.no-approved-personal-center-link {
  color: var(---B1, #5B82E5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
}

.no-approved-modal .invitation-wait-title {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 16px;
}

.no-approved-modal div.ant-modal-content {
  padding-bottom: 0;
  padding-top: 40px;
}

.no-approved-footer {
  display: flex;
  width: 100%;
  height: 42px;
  padding: 8px 16px 16px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 36px;
}

.no-approved-modal div.ant-modal-body {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
}

.no-approved-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.invitation-wait-content {
  color: #2d2d2d;
  font-size: 16px;
  line-height: 24px;
}

.invitation-wait-title {
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.invitation-wait-link {
  color: var(--Character-Secondary, #7A7B7B);
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}