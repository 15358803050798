@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, "PingFang SC", "BlinkMacSystemFont",
    "Helvetica Neue", "Microsoft Yahei", Arial, "Segoe UI", "Hiragino Sans GB",
    "Heiti SC", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 14px;
  line-height: 1.9;
  font-weight: 400;
  color: #2d2d2d;
  background-color: #fff;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .icon-hover {
    @apply text-secondary hover:text-neutral;
  }
}

.not-found {
  height: calc(100vh - 80px);
}

.home-first-page {
  min-height: calc(100vh - 64px);
}

.home-tab .ant-tabs-nav::before {
  border-bottom: none !important;
}

@keyframes animate-pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: animate-pulse 1.5s ease-in-out infinite;
}

#deep-lang-toast-container {
  z-index: 2000;
}

.ant-tour {
  width: 280px !important;
}

.highlight-note-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -9px;
  right: -8px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  z-index: 999;
  background: url("https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/icon/note-icon.png") no-repeat center center / contain;
}

.highlight-note-marker {
  display: inline-block;
  width: 16px;
  height: 16px;
  /* background-color: red; */
}

.highlight-note-icon-pdf {
  position: absolute;
  background: url("https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/icon/note-icon.png") no-repeat center center / contain;
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 999;
}

.dashboard {
  border: 1px solid #b6b6b6;
  background: radial-gradient(241.29% 133.69% at 6.04% 5.79%,
      rgba(241, 241, 241, 0.9) 0%,
      rgba(244, 244, 244, 0.9) 43.5%,
      rgba(239, 239, 239, 0.9) 75%,
      rgba(246, 246, 246, 0.9) 100%),
    #f5f5f5;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(40px);
}

.app {
  background: radial-gradient(241.29% 133.69% at 6.04% 5.79%,
      rgba(241, 241, 241, 0.9) 0%,
      rgba(244, 244, 244, 0.9) 43.5%,
      rgba(239, 239, 239, 0.9) 75%,
      rgba(246, 246, 246, 0.9) 100%),
    #f5f5f5;
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(40px);
}

.home {
  background: linear-gradient(180deg, #fefefe 0%, #f4f4f4 100%);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}

.scrollbar-hide {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

.module-box-shadow {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
}

.dl-skeleton-item {
  background-image: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  list-style: none;
  background-size: 400% 100%;
  background-position: 100% 50%;
  animation: dl-skeleton-loading 1.5s ease infinite;
}

.dl-skeleton-item2 {
  background-image: linear-gradient(90deg, transparent 25%, #e6e6e6 37%, transparent 63%);
  list-style: none;
  background-size: 400% 100%;
  background-position: 100% 50%;
  animation: dl-skeleton-loading 1.5s ease infinite;
}


@keyframes dl-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}


.ant-message {
  z-index: 2147483647 !important;
}

.single-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}

.word-break-all {
  word-break: break-all;
}