.invite-code-modal div.ant-modal-content {
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
}

.invite-code-modal div.ant-modal-content .ant-modal-close {
  top: 8px;
  right: 16px;
  font-size: 16px;
}

.invite-code-modal div.ant-modal-content .ant-modal-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.invite-code-modal div.ant-modal-content .ant-modal-body .invite-img {
  width: 100%;
}

.invite-code-modal div.ant-modal-content .ant-modal-body .invite-container {
  display: flex;
  flex-direction: column;
  padding: 33.5px 48px 0 48px;
  gap: 8px;
}

.invite-code-modal div.ant-modal-content .ant-modal-body .invite-container .invite-text {
  padding-bottom: 16px;
  color: #1a1a1a;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}


.invite-code-modal div.ant-modal-content .ant-modal-body .invite-container .invite-content {
  color: #2d2d2d;
  font-size: 16px;
  line-height: 24px;
}

.invite-code-modal div.ant-modal-content .ant-modal-body .invite-container .invite-desc-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 8px;
  list-style: none;
}

.invite-code-modal div.ant-modal-content .ant-modal-body .invite-container .invite-desc-list .invite-desc-item {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #7a7b7b;
  font-size: 14px;
  line-height: 22px;
}

.invite-code-modal .ant-modal-content .ant-modal-body .invite-container .invite-desc-list .invite-desc-item .icon-warn {
  padding-right: 4px;
  width: 14px;
  height: 22px;
}

.invite-code-modal .ant-modal-content .ant-modal-body .invite-container .invite-desc-list .invite-desc-item .icon-warn path {
  fill: #5b82e5;
}

.invite-code-modal .ant-modal-content .ant-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 16px 16px;
  margin: 0;
  gap: 8px;
}

.invite-code-modal .ant-modal-content .ant-modal-footer .apply-invitation-code {
  padding: 4px 60px;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}

.invite-code-modal .ant-modal-content .ant-modal-footer .disabled-invitation-btn {
  background: #f5f5f5;
  padding: 4px 15px;
  border-color: #d9d9d9;
  border-radius: 2px;
  color: #00000040;
}

.invite-code-modal .ant-modal-content .ant-modal-footer .apply-will-close {
  color: #2d2d2d;
  font-size: 12px;
  line-height: 18px;
}

.invite-code-modal .ant-modal-content .ant-modal-footer .apply-cancel {
  height: 18px;
  background-color: transparent;
  margin-inline-start: 0 !important;
  padding: 0;
  border: none;
  box-shadow: none;
  color: #b7b8b9;
  font-size: 12px;
  line-height: 18px;
}

.invite-code-modal .ant-modal-content .ant-modal-footer .apply-question {
  width: 100%;
  margin: 0;
  padding: 8px;
  padding-right: 16px;
  color: #b7b8b9;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
}

.invite-code-modal .ant-modal-content .ant-modal-footer .apply-question a {
  color: #b7b8b9;
}

.disabled-invitation-btn:hover,
.disabled-invitation-btn:active {
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.apply-cancel:hover,
.apply-cancel:active {
  border: none;
  box-shadow: none;
}

.invite-code-input-modal .ant-modal-content {
  padding: 32px 32px 24px 32px;
}

.invite-code-input-modal .invite-print {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.invite-code-input-modal .invite-print .invite-print-title {
  margin-bottom: 8px;
  color: #1a1a1a;
  font-size: 16px;
  line-height: 24px;
}

.invite-code-input-modal .invite-print .invite-input {
  padding: 5px 12px;
}

.invite-code-input-modal .ant-modal-footer {
  margin-top: 24px;
}


.invite-code-modal,
.invite-code-input-modal {
}

.invite-code-modal p,
.invite-code-input-modal p {
  margin-bottom: 0;
}

.ant-modal-footer .ant-btn-primary:hover,
.ant-modal-footer .ant-btn-primary:active {
  background-color: #575757;
  border-color: #575757;
}

.ant-modal-footer .ant-btn-default:hover,
.ant-modal-footer .ant-btn-default:active {
  border: 1px solid #575757;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-color: #575757;
}
